import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const SecondPage = () => (
  <Layout>
    <SEO title="Doctor Who on Blu-ray" />
    <h1>Doctor Who on Blu-ray</h1>
    <p>Doctor Who wasn't filmed in HD until mid-way through the revived series, but Blu-Ray still provides greater picture quality for all episodes, even Tom Baker episodes. Although most studio recording was on videotape, some episodes have a significant quantity of filmed sequences, which means an HD transfer was possible.</p>

      <ul>
          <li><b>Season 4 (1966-67)</b> Patrick Troughton
              <ul><li>The Macra Terror (animated) - <a href="https://amzn.to/2TqJn1b">Amazon UK</a> released 18 Mar 2019</li>
                  <li>The Power of the Daleks (animated) - <a href="https://amzn.to/2TmiY4n">Amazon UK</a> released 6 Feb 2017</li></ul>
          </li>
          <li><b>Season 12 (1975-76)</b> Tom Baker
              <ul><li>Complete Season Box Set - <a href="https://amzn.to/2DGedxl">Amazon UK</a> released 25 Feb 2019</li></ul>
          </li>
          <li><b>Season 17 (1979-80)</b> Tom Baker
              <ul><li>Shada (part animated) - <a href="https://amzn.to/2HBb45B">Amazon UK</a> released 4 Dec 2017</li></ul>
          </li>
          <li><b>Season 18 (1980-81)</b> Tom Baker
              <ul><li>Complete Season Box Set - <a href="https://amzn.to/2UrppmR">Amazon UK</a> released 25 Feb 2019</li></ul>
          </li>
          <li><b>Season 19 (1982)</b> Peter Davison
              <ul><li>Complete Season Box Set - <a href="https://amzn.to/2UnTEuU">Amazon UK</a> released 10 Dec 2018</li></ul>
          </li>
          <li><b>Series 1-10 + Specials</b>
              <ul>all exist, will add details soon!</ul>
          </li>
          <li><b>Series 11 (2018)</b> Jodie Whittaker
              <ul><li>Complete(ish) Season Box Set - <a href="https://amzn.to/2RtL8sE">Amazon UK</a> released Jan 2019</li>
                  <li>Resolution - <a href="https://amzn.to/2RVtDGp">Amazon UK</a> released 18 Feb 2019</li></ul>
          </li>
      </ul>



    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default SecondPage
